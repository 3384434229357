import React from 'react'
import { Container, Row, Col } from "react-bootstrap";
import { Section, Box, Title, Text, Button } from "../../components/Core";
import styled from 'styled-components'
import { caseStudies } from '../../data'
import { useStaticQuery, graphql, navigate } from "gatsby"

const CaseImage = styled.img`
    width: 100%;
    border-radius: 16px;
`

const CaseStudy = ({item}) => {
    return (
        <Col xs="12" lg="4" className="mb-5">
            <CaseImage src={item.coverImage}/>
            <Text variant="tag" className="mt-3">{item.tag}</Text>
            <Title variant="cardBig" className="mt-1">{item.title}</Title>
            <Text variant="p" css={`max-width: 750px;`}>{item.description}</Text>
            <Button onClick={() => navigate(item.link)} mt={2}>READ CASE STUDY</Button>
        </Col>
    )
}

const CaseStudies = () => {
    const data = useStaticQuery(graphql`
    {
      allFile(filter: { extension: { eq: "pdf" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
    `)
    const pdfs = data.allFile.edges
    const casesWithPDF = pdfs.map((pdf, index) => {
        let item
        switch(pdf.node.name) {
            case 'Allot':
                item = caseStudies[2]
                break
            case 'Acolyte':
                item = caseStudies[1]
                break
            default:
                item = caseStudies[0]
        }
        return {
            link: pdf.node.publicURL,
            ...item
        }
    })
    return (
        <Section className="position-relative">
            <Container fluid>
                <Row className="justify-content-center text-center">
                    <Col>
                        <Box mb="2.5rem">
                            <Title my={4}>Lessons from the field</Title>
                            <Text
                            variant="p"
                            >A collection of case studies in digital product design and development.
                            </Text>
                        </Box>
                    </Col>
                </Row>
                <Row className="d-flex justify-content-center">
                    { 
                        casesWithPDF.map(item => <CaseStudy item={item} />)
                    }                    
                </Row>
            </Container>
        </Section>
    )
}

export default CaseStudies
