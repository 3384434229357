import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from 'gatsby'

import { Container, Col } from "react-bootstrap";

import { Section, Box, Title, Text } from "../../components/Core";
import TwitterFeed from '../../components/TwitterFeed'

const Twitter = () => {
    const data = useStaticQuery(graphql`
    query User {
      user: twitterStatusesUserTimelineLatestTweets {
        user {
          id
          followers_count
          description
          profile_image_url_https
          screen_name
          url
        }
      }
      tweets: allTwitterStatusesUserTimelineLatestTweets {
        edges {
          node {
            id
            full_text
            favorite_count
            created_at
            retweet_count
            retweeted
            user {
              name
              screen_name
              url
              profile_image_url_https
            }
          }
        }
      }
    }
    `)
    const tweets = data.tweets
    const user = data.user
  return (
    <>
    
      {/* <!-- Works Area --> */}
      <Section className="position-relative">
        <Container>
          <Box mb="2.5rem" className="d-flex justify-content-center">
            <Title mb={4}>Follow us on Twitter</Title>
          </Box>
        </Container>
        <Container>
        
        <Box mb="2.5rem" className="d-flex justify-content-center">
            {/* <TwitterFeed tweets={tweets.edges} user={user.user} /> */}
            <a className="twitter-timeline" data-lang="en" data-width="500" data-height="600" data-theme="dark" href="https://twitter.com/AppSwagg?ref_src=twsrc%5Etfw">Tweets by AppSwagg</a>
        </Box> 
        </Container>
      </Section>
    </>
  );
};

export default Twitter;
