import React from 'react'
import styled from 'styled-components'
import { Text, Title } from '../Core'

const UserAvatar = styled.img`
    border: 1px #fff solid;
`

const TwitterUser = ({user}) => {
    console.log(user)
    const name = user.screen_name
    const url = user.profile_image_url_https
    return (
        <>
            <UserAvatar src={url} />
            <Title variant="card">{name}</Title>
        </>
    )
}


const Tweet = ({tweet}) => {
    let node = tweet.node
    const user = node.user
    const text = node.full_text
    return (
        <>
            <Text variant="p">{text}</Text>
            <Text variant="tag">hash</Text>
        </>
    )
}

const Container = styled.div`

`

const TwitterFeed = ({tweets, user}) => {
    return (
        <>
            <Container>
                <TwitterUser user={user}/>
                {
                    tweets.map((tweet, index) => {
                        return (<Tweet key={index} tweet={tweet}/>)
                    })
                }
            </Container>
        </>
    )
}

export default TwitterFeed
