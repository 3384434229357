import React, {useContext} from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-scroll";

import GlobalContext from "../../context/GlobalContext";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  ButtonOutline,
  ButtonIcon,
} from "../../components/Core";

import { device } from "../../utils";
// import bgHeroPattern from "../../assets/image/webp/hero_pattern.webp";
import imgDark from "../../assets/image/png/Hero_Image_Dark.png";
import imgLight from "../../assets/image/png/Hero_Image_Light.png";

const SectionStyled = styled(Section)`
  &::before {
    position: absolute;
    top: 0;
    content: "";
    width: 100%;
    height: 100%;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='96' viewBox='0 0 60 96'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%239c9c9d' fill-opacity='0.04'%3E%3Cpath d='M36 10a6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-12 0 6 6 0 0 0-6-6 6 6 0 0 1-6-6V10a6 6 0 1 1 12 0 6 6 0 0 0 12 0zm24 78a6 6 0 0 1-6-6 6 6 0 0 0-6-6 6 6 0 0 1-6-6V58a6 6 0 1 1 12 0 6 6 0 0 0 6 6v24zM0 88V64a6 6 0 0 0 6-6 6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-6 6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
`;

const ImgRight = styled.img`
  max-width: 50%;
  margin-bottom: 20px;
  @media ${device.sm} {
    max-width: 60%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

const Hero = () => {

  const gContext = useContext(GlobalContext);

  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled hero className="position-relative">
        <Container>
          <Row className="align-items-center">
            <Col lg="5" md="8" sm="9">
              <div className="text-center text-lg-right position-relative">
                <div className="img-main">
                  { !gContext.theme.bodyDark ? <ImgRight src={imgDark} alt="" /> : <ImgRight src={imgLight} alt="" />}
                </div>
              </div>
            </Col>
            <Col lg="7" className="mb-5 mb-lg-0">
              <Box pl={[0, null, null, "3.125rem"]}>
                <Title mb="2rem">Creating an app should be fun!</Title>

                <Text
                  css={`
                    line-height: 1.5;
                  `}
                  mb="2.5rem"
                >
                  We'll help you turn your desired future state to reality.
                  <br />
                  Fast and smooth.
                </Text>

                <Box>
                  {/* <Link
                    to="works"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={1000}
                  >
                    <ButtonOutline>Check Latest Works</ButtonOutline>
                  </Link> */}
                  <ButtonIcon
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}
                >
                  Let's Talk Now
                </ButtonIcon>
                </Box>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
